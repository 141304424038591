<template>
	<div class="subsidiary-card" tabindex="0" aria-label="Filialauswahl">
		<div class="subsidiary-image-border">
			<img v-if="subsidiary.image" :src="imageHref" />
			<div v-else class="subsidiary-image-fallback">
				<h3>{{ subsidiary.location.city }}</h3>
			</div>
		</div>
		<div class="subsidiary-adress-border">
			<!--adresse -->
			<p class="city bolded">{{ subsidiary.location.zip }} {{subsidiary.location.city}}</p>
			<p>{{subsidiary.location.street}} {{subsidiary.location.street_nr}}</p>
			<p v-if="subsidiary.phone"><a :href="phoneHref">Tel: {{ subsidiary.phone }}</a></p>
		</div>
	</div>
</template>

<script>
import {Config} from '../storage';

export default {
	name: "SubsidiaryCard",
  components: {

  },
	data(){
		return {};
	},
	props: {
		subsidiary: {
			type: Object,
			default: () => {return {}},
		},
	},
	computed: {
		phoneHref: function(){
			return `tel:${this.subsidiary.phone.replace(/[^0-9]/g, '')}`;
		},
    imageHref: function () {
      return Config.get('apiUrl') + `/${this.subsidiary.image}`;
    }
	}
}
</script>

<style scoped lang="scss">
	.subsidiary-card{
		background-color: $bgColor;
		width: 100%;
		display:flex;
		flex-direction: column;
		margin-bottom: 32px;
		cursor: pointer;
		
		.subsidiary-image-border{
			width: 100%;
      height: 175px;
      overflow: hidden;
			img{
				width: 100%;
        transform: scale(1);
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
        }
			}
		}
		
		.subsidiary-image-fallback{
			display:flex;
			flex-direction: column;
			justify-content: center;
			background-color: $mainColor;
			height: 175px;
			text-align: center;
			
			h3{
				font-size: 48px;
			}
		}
		
		.subsidiary-adress-border{
			display:flex;
			flex-direction: column;
			text-align: center;
			justify-content: space-evenly;
			min-height: 100px;
      @include md {
        min-height: 200px;
      }
			box-shadow: 0px 6px 4px -6px $shadowColor;

      .bolded {
        font-size: 18px;
      }
		}
	}
</style>