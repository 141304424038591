<template>
  <div class="burgerMenuWrapper" :class="(showBurgerMenu) ? 'openedMenu' : ''">
    <div class="clickable toggleBurgerMenu" @click="toggleBurgerMenu">
      <div></div>
    </div>
    <div class="menuContainer">
      <h3 class="bolded">Brauchst du hilfe?</h3>
      <div class="menuList">
        <a v-if="hrefPrivacy" :href="hrefPrivacy" target="_blank" class="clickable">
          <span class="label">Datenschutz</span>
          <span class="material-icons">open_in_new</span>
        </a>
        <a v-if="hrefImprint" :href="hrefImprint" target="_blank" class="clickable">
          <span class="label">Impressum</span>
          <span class="material-icons">open_in_new</span>
        </a>
        <a v-if="hrefConditions" :href="hrefConditions" target="_blank" class="clickable">
          <span class="label">AGB</span>
          <span class="material-icons">open_in_new</span>
        </a>
      </div>
      <div class="poweredByWrapper">
        <img v-bind:src="srcHolmaLogo" />
        <div>
          <a href="https://holma-software.de/" target="_blank">
            HOLMA - Bestell APP
          </a>
          &nbsp;ist ein Softwareprodukt der&nbsp;
          <a href="https://www.nosgroup.de/" target="_blank">
            NOSGROUP GmbH
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from "../Emitter";
import restClient from "../rest";

export default {
  name: "BurgerMenu",
  data(){
    return {
      showBurgerMenu: false,
      hrefImprint: '',
      hrefPrivacy: '',
      hrefConditions: '',
      srcHolmaLogo: './assets/Logo_Holma_Software.png'
    }
  },
  props: {
    mobile: {
      type: Boolean,
    }
  },
  async created(){
    //todo: daten laden
    const result = await restClient.ajax({
      endpoint: 'Holma/subsidiaryList',
      method: 'GET',
    });


    if(result.success){
      this.hrefPrivacy = result.data.privacy;
      this.hrefImprint = result.data.imprint;
      this.hrefConditions = result.data.agb;
      this.loaded = true;
    }else{
      //fehler, keine subsidiaries
    }
  },
  methods: {
    toggleBurgerMenu () {
      this.showBurgerMenu = !this.showBurgerMenu;
    },
    addToHomescreen () {
      emitter.emit('NavigationChange', {
        component: 'AddToHomescreen',
        properties: {},
      });
    }
  }
}
</script>

<style scoped lang="scss">
  .burgerMenuWrapper {
    top: 12px;
    right: 12px;
    position: absolute;
    background: $bgColor;
    border-radius: 4px;
    padding: 8px;
    width: 42px;
    transition: all 0.15s;
    .toggleBurgerMenu {
      width: 28px;
      &:before,
      &:after,
      div {
        background: $bgDark;
        content: "";
        display: block;
        height: 4px;
        border-radius: 3px;
        margin: 4px 0;
        transition: all 0.5s;
      }
    }
    .menuContainer {
      display: none;
      padding: 12px;
      .menuList {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        a {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 8px 0;
        }
        .seperator {
          border: 1px solid $lightFont;
          margin: 20px;
        }
        button {
          text-align: center;
        }
      }
      .poweredByWrapper {
        position: absolute;
        bottom: 20px;
        text-align: center;
        div, a {
          font-size: 16px;
        }
        img {
          width: 30%;
        }
        a {
          cursor: pointer;
          text-decoration: underline;
          color: $lightFont;
        }
      }
    }
    &.openedMenu {
      position: fixed;
      top: 0;
      right: 0;
      border-radius: 0;
      width: 300px;
      height: 100%;
      background: $bgDark;
      color: $lightFont;
      z-index: 101;
      @include md {
        width: 450px;
      }
      .toggleBurgerMenu {
        position: absolute;
        top: 20px;
        right: 20px;
        &:before,
        &:after,
        div {
          background: $lightFont;
        }
        &:before {
          transform: translateY(8px) rotate(135deg);
        }
        &:after {
          transform: translateY(-8px) rotate(-135deg);
        }
        div {
          transform: scale(0);
        }
      }
      .menuContainer {
        display: block;
      }
    }
  }
</style>