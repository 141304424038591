<template>
  <ModalTemplate
      @close="showModal = false"
      :showModal="showModal"
      :modalType="modalType"
      :headline="modalHeadline"
      :message="modalMessage"
      :product="selectedProduct"
  />
	<div class="product-listing">
		<BackButton component="SubsidiarySelector" componentName="Filialübersicht" />
		<div class="nav">
			<div
				class="clickable"
				@click="toggleSearch"
				role="button"
				:aria-label="searchVisible? 'Suche ausblenden' : 'Suche einblenden'"
			>
				<span class="material-icons">search</span>
			</div>
			<div v-if="searchVisible == false" class="categories">
				<template  v-for="category in categories" :key="category.id">
					<div
						class="category-button clickable"
						:class="{selected: category.selected}"
						@click="toggleCategorySelect(category)"
						role="button"
						:aria-label="`Kategorie ${category.name} ${category.selected? 'ausblenden' : 'einblenden'}`"
					>
						{{ category.name }}
					</div>
				</template>
			</div>
      <div v-if="searchVisible" class="search">
        <input type="text" v-model="searchString" ref="searchInput" />
        <span
            class="control clickable searchCancel"
            @click="cancelSearch"
        >
          <span class="material-icons">cancel</span>
        </span>
      </div>
		</div>
		<div class="currentFilters">
		
		</div>
		<div v-if="loaded" class="listing">
			<div v-if="selectedCategory === 0">
        <div v-for="category in categories" :key="category.id">
          <div v-if="category.id !== 0">
            <div class="category-heading">
              <h2 class="bolded">{{ category.name }}</h2>
            </div>
            <template v-for="product in filteredProducts" :key="product.id">
              <div class="product" v-if="productHasCategory(product, category)">
                <Product @showModal="renderModal" :product="product" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="visibleProductCount" v-for="category in categories" :key="category.id">
          <div v-if="category.selected">
            <div class="category-heading">
              <h2 class="bolded">{{ category.name }}</h2>
            </div>
            <template v-for="product in filteredProducts" :key="product.id">
              <div class="product" v-if="productHasCategory(product, category)">
                <Product @showModal="renderModal" :product="product" />
              </div>
            </template>
          </div>
        </div>
        <div v-else>
          Kein Ergebniss gefunden
        </div>
      </div>
		</div>
		<div class="spinner-border" v-else>
			<PulseLoader :loading="!loaded" color="#FDA87B" />
		</div>
		<div v-if="basketHasItem" class="basket-container">
			<div class="basket-button" @click="showBasket" role="button" aria-label="Zum Warenkorb">
				<span class="material-icons icon">
					shopping_basket
				</span>
				<span class="text">
					Warenkorb ({{basketSum}})
				</span>
			</div>
		</div>
		<div class="sticky_bottom">
			 <div id ="paypal_buttons"> </div>
		</div>
	</div>
</template>

<script>
import BackButton from './BackButton';
import Product from './Product';
import {cloneObject} from '../util';
import {Basket, Config} from '../storage';
import emitter from '../Emitter';
import restClient from '../rest';
import {PriceFormatter} from '../util';
import PulseLoader from 'vue-spinner/src/PulseLoader';
import ModalTemplate from "./ModalTemplate";

export default {
	name: "ProductListing",
	components: {
    ModalTemplate,
		BackButton,
		Product,
		PulseLoader,
	},
	data(){
		return {
			mySubsidiary: null,
			basket: {},
			categories: [],
      selectedCategory: '',
			products: [],
			searchVisible: false,
			searchString: '',
			loaded: false,
      showModal: false,
      modalType: '',
      modalHeadline: '',
      modalMessage: '',
      selectedProduct: {},
		};
	},
	props: {
		subsidiary: {
			type: Object,
		}
	},
	async created(){
		//todo: load data
		const result = await restClient.ajax({
			endpoint: 'Holma/articleList',
			method: 'GET',
      data: {
        orderDate: Basket.get('orderDate')
      }
		});
		
		if(result.success){
			this.products = result.data;
			this.loaded = true;
		}else{
			//fehler keine daten
		}
		this.mySubsidiary = this.subsidiary;
		
		if(!this.mySubsidiary && !Basket.get('lastSubsidiary')){
			emitter.emit('navigationChange', {
				component: 'SubsidiarySelector',
				componentDisplayName: 'Filialauswahl',
				properties: {},
			});
			return;
		}else{
			if(!this.mySubsidiary){
				this.mySubsidiary = Basket.get('lastSubsidiary');
			}else{
				const lastSubsidiary = Basket.get('lastSubsidiary');
				if(!lastSubsidiary || lastSubsidiary.id !== this.mySubsidiary.id){
					Basket.set('lastSubsidiary', cloneObject(this.mySubsidiary));
					Basket.set('basket', {});
				}
			}
		}
		this.products = this.products.sort((a, b) => a.name > b.name);
		this.basket = Basket.get('basket', {});
		
		emitter.on('basketChanged', () => {
			this.basket = Basket.get('basket', {});
		});
		
		this.products.forEach(product => {
			product.amount = this.basket[product.id] ? this.basket[product.id] : 0;
		});
		
		
		const categories = {};
		this.products.forEach(product => {
			const cloned = cloneObject(product.categories);
			cloned.forEach(cat => {
				cat.selected = false;
				if(typeof categories[cat.id] === 'undefined'){
					categories[cat.id] = cat;
				}
			});
		});
		this.categories = Object.values(categories).sort((a, b) => a.name > b.name);
    this.categories.unshift({
      id: 0,
      name: 'Alle',
      selected: true
    });
    this.selectedCategory = 0;

    this.tmp = Basket.get('lastSubsidiary');
	},
	computed:{
		basketHasItem(){
			return Object.keys(this.basket).length > 0;
		},
		basketSum(){
			let sum = 0;
			for(let i in this.basket){
				for(let o of this.products){
					if(o.id === i){
						sum+= o.price * this.basket[i];
					}
				}
			}
			
			return PriceFormatter.format(sum);
		},
		filteredProducts(){
			const products = cloneObject(this.products);
			const search = this.searchString
				.trim()
				.toLocaleLowerCase()
				.split(' ');
			if(search.length === 0){
				return products;
			}
			
			return products.filter(product => {
				let contains = true;
				for(let substr of search){
					if(
						!product.name.toLowerCase().includes(substr)
						&& !product.description.toLowerCase().includes(substr)
					){
						contains = false;
						break;
					}
				}
				
				return contains;
			});
		},
		visibleProductCount(){
			const selectedCategories = this.categories.filter((cat) => cat.selected);
			if (this.selectedCategory === 0) {
        return true;
      } else {
        if(selectedCategories.length === 0){
          return 0;
        }

        return this.filteredProducts.filter(prod => {
          for(let cat of selectedCategories){
            for(let prodCat of prod.categories){
              if(prodCat.id === cat.id){
                return true;
              }
            }
          }
        }).length;
      }
		}
	},
	methods: {
    renderModal (params) {
      this.modalType = params.type;
      this.modalHeadline = params.headline;
      this.modalMessage = params.message;
      this.selectedProduct = params.product;

      this.showModal = true;
    },
		showBasket(){
			emitter.emit('navigationChange', {
				component: 'Basket',
				componentDisplayName: 'Warenkorb',
				properties: {
					products: cloneObject(this.products),
				},
			});
		},
		productHasCategory(product, category){
			let has = false;
			for(let cat of product.categories){
				if(cat.id === category.id){
					has = true;
					break;
				}
			}

			return has;
		},
		toggleCategorySelect(category){
      this.categories.forEach(function (item, index) {
        if (item.id == category.id) {
          item.selected = true;
          this.selectedCategory = item.id;
        } else {
          item.selected = false;
        }
      },this);
			//category.selected = !category.selected;
		},
		toggleSearch(){
			this.searchVisible = !this.searchVisible;
			if(this.searchVisible){
				this.$nextTick(() => {
					this.$refs.searchInput.focus();
				});
			}
		},
    cancelSearch () {
      this.searchString = '';
      this.searchVisible = false;
    }
	},

}
</script>

<style scoped lang="scss">
	.spinner-border{
		margin-top: 10vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		min-height: 50vh;
	}
  
  .sticky_bottom{
    position: fixed;
    height:100px;
    top: 100vh;

  }
	.product-listing{
		position: relative;
		width: 100%;
		
		.nav{
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

      .clickable {
        display: flex;
        align-items: center;
      }
			
			.categories{
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				min-width: 90%;
        overflow: scroll;
        padding-bottom: 7px;
				.category-button{
					padding: 0 10px 0 10px;
          margin: 2px;
					border-radius: 20px;
          white-space: nowrap;
					&.selected{
						background-color: $mainColor;
					}
				}
			}
      .search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        input {
          width: calc(90% - 20px);
          margin: 2px 0 6px 0;
          padding: 0 30px;
          background: transparent;
          border-bottom: 1px solid $shadowColor;
        }
        .searchCancel {
          margin: 0 0 4px -20px;
        }
      }
		}
		
		.listing{
			width: 100%;
			.category-heading{
				width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 8px 8px -2px rgba(0,0,0,0.1);
			}
		}
		
		.basket-container{
			position: sticky;
			bottom: 0px;
      padding: 24px 0;
      background: $bgColor;
			width: 100%;
			
			.basket-button{
				display: flex;
				flex-direction: row;
				margin: 0 auto;
				padding: 8px;
				border-radius: 25px;
				background-color: $mainColor;
				color: $lightFont;
				align-items: center;
				max-width: 700px;
				.icon{
					margin-left: 50px;
					margin-right: -50px;
				}
				.text{
					margin: 0 auto 0 auto;
				}
			}
		}
	}
</style>